<template>
    <div class="user-invoice">
        <el-card class=" " :body-style="{height: '130px'}"  shadow="never">
            <div slot="header" class="clearfix">
                <span>发票管理</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-row class="content">
                <el-col :span="5">
                    <p>
                        <span>可开票金额：</span>
                    </p>
                    <p>
                        <span>￥{{sum.toFixed(2) || 0.00}}元</span>
                    </p>
                    <p>
                        <el-button @click="show()" size="mini" type="danger">申请开票</el-button>
                    </p>
                </el-col>
                <el-col :span="1">

                </el-col>
                <el-col :span="16">
                    <p>温馨提示:</p>
                    <p>1、网站提供的发票均为增值税电子普通发票，电子普通发票是税局认可的有效凭证，其法律效力、基本用途及使用规定同纸质发票。</p>
                    <p>2、开票仅针对已消费金额，如果您想将充值金额开票，可消费后再申请开票。</p>
                    <p>3、发票内容：*信息技术服务*服务费，发票税率为6%。</p>
                    <p>4、如需加急处理或开票失败请联系客服人员。</p>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="box-card list-card"  shadow="never">
            <div slot="header" class="clearfix">
                <el-tabs v-model="activeName" @tab-click="handleClick">

                    <el-tab-pane label="可开票" name="normal">

                    </el-tab-pane>
                    <el-tab-pane label="已申请" name="invoiced">

                    </el-tab-pane>
                    <el-tab-pane label="开票成功" name="invoicable">

                    </el-tab-pane>
                    <el-tab-pane label="开票失败" name="reject">

                    </el-tab-pane>

                </el-tabs>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-table
                    :data="listData.data"
                    border
                    style="width: 100%">
                <el-table-column

                        prop="title"
                        label="标题"
                        width="300">
                </el-table-column>
<!--                <el-table-column-->
<!--                        prop="name"-->
<!--                        label="发票抬头"-->
<!--                        width="150">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="phone"-->
<!--                        label="联系电话"-->
<!--                        width="120">-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="email"-->
<!--                        label="邮箱"-->
<!--                        width="120">-->
<!--                </el-table-column>-->
                <el-table-column
                        align="center"
                        header-align="center"
                        prop="payamount"
                        label="开票金额"
                        width="110">
                </el-table-column>
              <el-table-column
                        align="center"
                        header-align="center"
                        prop="fail_reasons"
                        label="开票失败说明"
                        v-if="activeName === 'reject'"
                        width="220"
              >
                </el-table-column>
                <el-table-column
                        align="center"
                        header-align="center"
                        prop="status"
                        label="状态"
                        width="110">
                    <template slot-scope="scope">
                        <span>
                            {{getStatus(scope.row.status)}}
                        </span>
                    </template>
                </el-table-column>
              <el-table-column
                  align="center"
                  header-align="center"
                  prop="createtime"
                  label="日期"
                  width="170"
              >
                <template slot-scope="scope">
                        <span>
                            {{$formatDate(scope.row.createtime, true)}}
                        </span>
                </template>
              </el-table-column>
                <el-table-column
                        align="center"
                        header-align="center"
                        prop=""
                        label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                v-if="activeName === 'normal'"
                                @click="show(scope.row, 'open')">申请开票</el-button>
                        <el-button
                                size="mini"
                                v-if="activeName === 'invoiced'"
                                @click="show(scope.row, 'edit')">编辑</el-button>
                        <el-button
                                size="mini"
                                v-if="activeName === 'reject'"
                                @click="show(scope.row, 'again')">重新申请</el-button>
                        <el-button
                                size="mini"
                                v-if="activeName !== 'normal'"
                                @click="show(scope.row, 'detail')">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                style="text-align: center;margin-top: 10px"
                background
                layout="prev, pager, next"
                :current-page="listData.current_page"
                :page-size="Number(listData.per_page)"
                :page-count="listData.last_page"
                @current-change="currentChange"
                :total="listData.total">
        </el-pagination>
        <el-dialog
                title="发票信息"
                :visible.sync="dialogFormVisible"
                width="450px"
                :modal="false"
        >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="开票金额：" prop="payamount">
                    <span style="color: red">{{ruleForm.payamount}}</span>
                </el-form-item>
                <el-form-item label="抬头类型：" prop="usertype">
                    <span v-if="isDetail">{{ruleForm.usertype}}</span>
                    <el-radio-group v-model="ruleForm.usertype" v-else>
                        <el-radio label="P">个人</el-radio>
                        <el-radio label="C">单位</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="发票抬头：" prop="usertitle">
                    <span v-if="isDetail">{{ruleForm.usertitle}}</span>
                    <el-input type="text" v-model="ruleForm.usertitle" v-else ></el-input>
                </el-form-item>
                <el-form-item label="税号：" prop="taxcode" v-if="ruleForm.usertype==='C'">
                    <span v-if="isDetail">{{ruleForm.taxcode}}</span>
                    <el-input type="text" v-model="ruleForm.taxcode" v-else ></el-input>
                </el-form-item>
                <el-form-item label="邮箱：" prop="email">
                    <span v-if="isDetail">{{ruleForm.email}}</span>
                    <el-input type="text" v-model="ruleForm.email" v-else ></el-input>
                </el-form-item>
                <el-form-item label="联系人：" prop="name">
                    <span v-if="isDetail">{{ruleForm.name}}</span>
                    <el-input type="text" v-model="ruleForm.name" v-else ></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" prop="phone">
                    <span v-if="isDetail">{{ruleForm.phone}}</span>
                    <el-input type="text" v-model="ruleForm.phone" v-else ></el-input>
                </el-form-item>
                <el-form-item label="地址：" prop="address">
                    <span v-if="isDetail">{{ruleForm.address}}</span>
                    <el-input type="text" v-model="ruleForm.address" v-else ></el-input>
                </el-form-item>
              <el-form-item label="失败原因：" prop="fail_reasons" v-if="ruleForm.status === 'reject'">
                    <span >{{ruleForm.fail_reasons}}</span>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer" >
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="summit()" v-if="!isDetail">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {UserService} from "../../../api/user";

    export default {
        name: "user-order-index",
        data() {
            return {
                activeName: 'normal',
                page: 1,
                sum: 0,
                dialogFormVisible: false,
                isDetail: false,
                ruleForm: {
                    email: '',
                    name: '',
                    usertype: 'P',
                    taxcode: '',
                    usertitle: '',
                    phone: '',
                    payamount: 0,
                    address: ''
                },
                formLabelWidth: '100px',
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    data: [],
                    per_page: 1,
                    total: 0
                },
                rules: {
                    email: [
                        { required: true, message: '请填写邮箱地址', trigger: 'blur' }
                    ],
                    taxcode: [
                        { required: true, message: '请填写税号', trigger: 'blur' }
                    ],
                    usertitle: [
                        { required: true, message: '请填写发票抬头', trigger: 'blur' }
                    ]
                },
            }
        },
        methods: {
            show(item, type) {
                // debugger
                this.isDetail = type === 'detail'
                if (!item) {
                    if (this.sum <= 0) {
                        this.$message.error('开票金额必须大于0')
                        return
                    }
                    this.ruleForm.payamount = this.sum.toFixed(2) + ''
                    delete this.ruleForm.id
                } else {
                    this.ruleForm = {...{}, ...item}
                    // this.ruleForm.payamount = item.payamount + ''
                }
                this.dialogFormVisible = true
            },
            summit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        UserService.setInvoice(this.ruleForm).then(res => {
                            // UserService.setInvoice({params: this.ruleForm}).then(res => {
                            this.dialogFormVisible = false
                            this.$message.success('操作成功')
                            this.page = 1
                            // this.getSum()
                            this.getList()
                        })
                    } else {
                        return false;
                    }
                });

            },
            getSum() {
                UserService.invoiceSum().then(res => {
                    // // console.log('res', res)
                    this.sum = res.data.sum
                    if (res.data.last) {
                        Object.keys(this.ruleForm).forEach(key => {
                            this.ruleForm[key] =  res.data.last[key]
                        })
                    }
                })
            },
            handleClick(tab, event) {
                this.listData.data = []
                this.page = 1
                this.getList()
            },
            getStatus(status) {
              return {
                  'normal': '可开票',
                  'invoiced': '已申请',
                  'reject': '拒绝开票',
                  'invoicable': '开票成功'
              }[status]
            },
            currentChange(page) {
                this.page = page
                this.getList()
            },

            getList() {
                this.getSum()
                UserService.invoice({ type: this.activeName, page: this.page }).then(res => {
                    this.listData = res.data

                })
            }
        },
        mounted() {
            // this.getSum()
            this.getList()
        }
    }
</script>

<style scoped lang="scss">
    .user-invoice{
        .content{
            /*height: 200px;*/
            height: 100%;
            .left{
                span{
                    color: $font_1;
                    font-size: 18px;
                }
            }
            .el-col:nth-child(1) {
                /*display: flex;*/
                /*justify-content: center;*/
                /*align-items: center;*/
                height: 100%;
                p:nth-child(1){
                    span{
                        font-size: 12px;
                        color: $font_2;
                    }
                }
                p:nth-child(2){
                    span{
                        font-size: 18px;
                        color: $danger;
                    }
                }
                .el-button{
                    /*margin-left: 20px;*/
                    /*float: right;*/
                }
            }
            .el-col:nth-child(2) {
                width: 1px;
                height: 100%;
                background-color: $border_1;
                margin: 0 20px;

            }
            .el-col:nth-child(3) {
                /*padding: 10px 0;*/
                margin-top: -20px;
                p{
                    font-size: 13px;
                    color: $font_2;
                    /*line-height: 1.6;*/
                    margin: 10px 0;
                }
            }
        }
        .list-card{
            height: 470px;
            margin-top: 13px;
        }
    }

</style>